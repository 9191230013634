import React from 'react';

import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import { Section } from '../components/layouts/Containers';
import { Headline, P } from '../components/elements/Typography';
import { Link } from '../components/elements/ButtonsAndLinks';
import { List, ListItem } from '../components/elements/Lists';

const TermsPage = () => (
    <BaseLayout
        title="Teilnahmebedingungen"
        description="Teilnahmebedingungen für das Bewerbungsverfahren beim LOTTO Sportjugend-Förderpreis"
    >
        <Stage>
            <StageIntro
                headline={{ text: 'Teilnahmebedingungen', smaller: true }}
                copy="für das Bewerbungsverfahren beim Lotto Sportjugend-Förderpreis"
            />
        </Stage>
        <Section container="s">
            <Headline level="h2">I Allgemeines</Headline>
            <List type="ol" gap="xxxl" itemsHaveBorder>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Der Vereinswettbewerb „LOTTO Sportjugend-Förderpreis“ ist eine Initiative der
                    Staatlichen Toto-Lotto GmbH Baden-Württemberg (nachfolgend „LottoBW“) in
                    Zusammenarbeit mit der Baden-Württembergischen Sportjugend im Landessportverband
                    („BWSJ“), dem Ministerium für Kultus, Jugend und Sport Baden-Württemberg
                    („MKJS“) und mit freundlicher Unterstützung der Europa-Park GmbH & Co Mack KG
                    („Europa-Park“).
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Der Wettbewerb würdigt das gesellschaftspolitisch wirksame Leistungsspektrum von
                    Sportvereinen und deren besonderes ehrenamtliches Engagement im Bereich der
                    Vereinsjugendarbeit. Veranstalter des Wettbewerbs ist die Staatliche Toto-Lotto
                    GmbH Baden-Württemberg, Nordbahnhofstr. 201, 70191 Stuttgart.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Mit der Teilnahme am Wettbewerb akzeptieren die Teilnehmer diese
                    Teilnahmebedingungen.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Teilnehmen können alle Sportvereine, die in den Mitgliedssportbünden bzw.
                    Sportfachverbänden des Landessportverbandes Baden-Württemberg organisiert sind.
                    Sportfachverbände und Sportkreise sind von der Teilnahme ausgeschlossen.
                    Kooperationsprojekte zwischen Sportvereinen und Fachverbänden/Sportkreisen sind
                    jedoch zulässig.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Von der Teilnahme ausgeschlossen sind:
                    <List>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            Einsendungen, die gegen diese Teilnahmebedingungen oder gegen
                            gesetzliche Regelungen verstoßen bzw. ein entsprechender Verstoß nicht
                            ausgeschlossen werden kann.
                        </ListItem>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            Einsendungen mit rechtswidrigen, unsittlichen, pornographischen,
                            politischen, religiösen, rassistischen, gewaltverherrlichenden,
                            beleidigenden oder in anderer Weise anstößigen Inhalten.
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Ausgeschlossen werden können (auch nachträglich) auch Teilnehmer, die sich
                    unerlaubter Hilfsmittel bedienen oder sich anderweitig durch Manipulation oder
                    falsche Personenangaben bzw. Angaben zum Verein oder der Sache Vorteile
                    verschaffen.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Der Ausschluss, die Sperrung oder die Löschung von Wettbewerbsbeiträgen liegt im
                    freien Ermessen von LottoBW und kann ohne Anhörung der betroffenen Teilnehmer zu
                    jeder Zeit erfolgen. Jedwede Ansprüche wegen des Ausschlusses, der Sperrung oder
                    Löschung von Wettbewerbsbeiträgen sind gegenüber LottoBW ausgeschlossen.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Die Teilnahme ist nur innerhalb der in der Ausschreibung genannten Frist
                    möglich.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Über die Vergabe der Preise entscheidet eine Jury. Die Jury behält sich vor, die
                    Anzahl der Preise entsprechend zu reduzieren, falls weniger Bewerbungen
                    eingehen, die den Kriterien entsprechen. Die Teilnehmer werden im Anschluss an
                    die Juryentscheidung von LottoBW über das Abschneiden informiert. Die
                    Entscheidung der Jury ist endgültig und nicht anfechtbar. Der Rechtsweg ist
                    diesbezüglich ausgeschlossen.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Mit Abgabe einer Bewerbung erklärt der Absender zugleich,
                    <List>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            dass er zur Abgabe der Bewerbung und Akzeptanz dieser Bedingungen im
                            Namen des in der Bewerbung genannten Vereins berechtigt ist und diesen
                            jedenfalls insoweit vertreten darf,
                        </ListItem>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            dass der derart vertretene Verein alle für die Veröffentlichung bzw.
                            Verwendung des im Rahmen der Bewerbung eingereichten bzw. zur Verfügung
                            gestellten Bildmaterials (Fotos, Filme) erforderlichen Rechte besitzt,
                        </ListItem>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            dass alle auf dem eingereichtem/zur Verfügung gestelltem Bildmaterial
                            erkennbaren Personen ihre Einwilligung zur Verwendung der Bildnisse
                            erteilt haben und dies im Zweifelsfall schriftlich bestätigt werden
                            kann,
                        </ListItem>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            dass mit der Bewerbung eingereichtes Bildmaterial im Rahmen der
                            Berichterstattung über den Wettbewerb verwendet werden darf, sofern der
                            Einreicher nicht selbst diese Rechte innehat
                        </ListItem>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            dass LottoBW im Rahmen der Preisverleihung im Europa-Park berechtigt
                            ist, Ton,- Foto- und Filmaufnahmen von den Teilnehmern zu erstellen bzw.
                            erstellen zu lassen und diese unentgeltlich zu verwenden, insbesondere
                            zu veröffentlichen und dass der Einreicher dies zusichert, dass die
                            Betroffenen damit einverstanden sind bzw. er deren Einverständnis bis
                            zur Preisverleihung eingeholt hat.
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Wenn ein Verein am Wettbewerb teilnimmt,
                    <List>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            werden die Bewerbung und die mit ihr verbundenen personenbezogenen Daten
                            und Angaben zum Zweck der ordnungsgemäßen Durchführung des Wettbewerbs
                            mit den an der Durchführung beteiligten Stellen (z.B. Jury und
                            Kooperationspartner) ausgetauscht,
                        </ListItem>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            wird das mit der Bewerbung eingereichte Bildmaterial im Rahmen der
                            Berichterstattung über den Wettbewerb verwendet,
                        </ListItem>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            werden LottoBW und seine Kooperationspartner das Abschneiden des Vereins
                            im Gewinnfall in Online- wie Offlinemedien veröffentlichen, z.B. über
                            eine Pressemitteilung, das unternehmenseigene Kundenmagazin
                            „glüXmagazin“, die Unternehmenshomepage und die Social Media-Kanäle, die
                            von LottoBW besetzt sind,
                        </ListItem>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            werden Wettbewerbsbeiträge von LottoBW in Online- wie Offlinemedien
                            genutzt, verbreitet sowie auf sonstige Weise Dritten gegenüber
                            zugänglich gemacht. Für diese Zwecke ist es Lotto BW außerdem erlaubt,
                            falls es erforderlich ist, Dritten entsprechende Nutzungsrechte
                            einzuräumen; diese Rechteeinräumung erfolgt ohne räumliche, inhaltliche
                            oder zeitliche Beschränkung.
                        </ListItem>
                        <ListItem
                            gap={{
                                small: 'l',
                                medium: 'xl',
                                large: 'xxl',
                            }}
                        >
                            ist LottoBW im Rahmen der Preisverleihung im Europa-Park berechtigt,
                            Ton,- Foto- und Filmaufnahmen von den Teilnehmern zu erstellen bzw.
                            erstellen zu lassen und diese unentgeltlich zu verwenden, insbesondere
                            zu veröffentlichen.
                        </ListItem>
                    </List>
                </ListItem>
            </List>

            <Headline level="h2">II. Gewinn</Headline>
            <P>Der LOTTO Sportjugend-Förderpreis ist mit insgesamt 100.000 Euro dotiert.</P>
            <P>
                In zehn Regionen des Landes werden jeweils zehn Preisträger ermittelt. Die Preise
                sind wie folgt dotiert: <br />
                <br />
                1. Preis: 2.000 Euro, <br />
                2. Preis: 1.500 Euro, <br />
                3. Preis: 1.200 Euro, <br />
                4. Preis: 1.000 Euro, <br />
                5. Preis: 800 Euro, <br />
                6. Preis: 500 Euro <br />
            </P>
            <P>
                Unabhängig von der Region werden weitere bis zu 10 Preisträger ermittelt, die ein
                Preisgeld von 500 Euro erhalten.
            </P>
            <P>
                Unter allen Teilnehmern werden drei Landessieger ermittelt. Die Preise sind wie
                folgt dotiert:<br />
                <br />
                1. Preis: 7.500 Euro,<br />
                2. Preis: 5.000 Euro,<br />
                3. Preis: 2.500 Euro
            </P>
            <P>Zusätzlich werden bis zu zehn mit je 1.000 Euro dotierte Sonderpreise vergeben.</P>
            <P gap="xxxl">Im Übrigen wird auf I (9) verwiesen.</P>

            <Headline level="h2">III Haftung</Headline>
            <List type="ol" gap="xxxl">
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    LottoBW haftet nur für Schäden, die von LottoBW oder einem ihrer
                    Erfüllungsgehilfen vorsätzlich oder grob fahrlässig oder durch die Verletzung
                    von Kardinalpflichten verursacht wurden. Dies gilt nicht für die Verletzung von
                    Leben, Körper und/oder Gesundheit.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Voranstehende Haftungsbeschränkung gilt auch für Schäden, die durch Fehler,
                    Verzögerungen oder Unterbrechungen in der Übermittlung, technische Störungen,
                    falsche und unvollständige Inhalte, Verlust oder Löschung von Daten, Viren oder
                    in sonstiger Weise bei der Teilnahme am Wettbewerb entstehen können.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Ferner haftet LottoBW nicht für Sach- und Rechtsmängel der von
                    Kooperationspartnern gestifteten Preise und/oder für die Insolvenz des
                    Kooperationspartners/Preissponsors und daraus resultierenden Folgen für die
                    Durchführung des Wettbewerbs. Soweit vom Gewinner Ansprüche im Zusammenhang mit
                    den erhaltenen Gewinnen geltend gemacht werden, sind diese, soweit rechtlich
                    zulässig, unmittelbar gegen den Hersteller/Händler, Lieferanten oder sonstigen
                    Leistungsträger zu richten. Sollten diesbezügliche Ansprüche bei LottoBW
                    entstehen, werden diese an die jeweiligen Gewinner abgetreten.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Weiterhin übernimmt Lotto BW keine Garantie dafür, dass die Internetseite
                    https://www.lotto-bw.de/sjfp bzw. sportjugendfoerderpreis,de auf dem jeweiligen
                    Teilnehmerrechner ordnungsgemäß funktionsfähig ist.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Soweit die Haftung des Veranstalters ausgeschlossen oder beschränkt ist, gilt
                    dies auch für die persönliche Haftung von Arbeitnehmern, Vertretern und
                    Erfüllungsgehilfen des Veranstalters.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Für den Fall der Einreichung bzw. des Uploads von Bildern durch den
                    teilnehmenden Verein, haftet der teilnehmende Verein gegenüber LottoBW für die
                    Wirksamkeit und den Bestand der eingeräumten Rechte am Bild und stellt im Falle
                    einer Inanspruchnahme durch Dritte Lotto BW von sämtlichen Ansprüchen dieser
                    Dritten frei, die in Bezug auf die vom Teilnehmer zur Verfügung gestellten oder
                    hochgeladenen Bilder geltend gemacht werden.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Der Teilnehmer stellt Lotto BW von sämtlichen Ansprüchen Dritter frei, die von
                    diesen aufgrund von Beleidigungen oder Behauptungen falscher Tatsachen von
                    Teilnehmern eingereichten bzw. hochgeladenen Inhalten geltend gemacht werden.
                    Ebenso bei Ansprüchen aus Urheberrechtsverletzungen von hochgeladenen oder
                    einegereichten Bildern oder Texten.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Alle Gewinnerangaben, insbesondere im Zusammenhang mit der Veröffentlichung von
                    Vereinsnamen, etc. erfolgen ohne Gewähr.
                </ListItem>
            </List>

            <Headline level="h2">IV Gewährleistungsausschluss</Headline>
            <List type="ol" gap="xxxl">
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Lotto BW weist darauf hin, dass die Verfügbarkeit und Funktion des Wettbewerbs
                    nicht gewährleistet werden kann. Der Wettbewerb kann aufgrund von äußeren
                    Umständen oder Zwängen beendet oder entfernt werden, ohne dass hieraus Ansprüche
                    der Teilnehmer gegenüber Lotto BW oder seiner Kooperationspartner entstehen.
                    Dasselbe gilt auch für die Preisverleihung.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Lotto BW behält sich vor, den Wettbewerb zu jedem Zeitpunkt ohne Vorankündigung
                    abzubrechen oder zu beenden. Von dieser Möglichkeit macht Lotto BW insbesondere
                    dann Gebrauch, wenn aus technischen Gründen (z. B. Viren in Computersystemen,
                    Manipulation oder Fehler in der Hard-/Software) oder aus rechtlichen Gründen
                    eine ordnungsgemäße Durchführung des Wettbewerbs nicht gewährleistet werden kann
                    oder die Durchführung des Wettbewerbs von einer Aufsichtsbehörde untersagt wird.
                    Dasselbe gilt auch für die Preisverleihung. Nach Teilnahmeschluss kann der
                    Wettbewerb für ungültig erklärt werden, wenn aufgrund von Erkenntnissen nicht
                    ausgeschlossen werden kann, dass der Wettbewerb so manipuliert wurde, dass dies
                    Einfluss auf das Ergebnis oder die Gewinnchancen gehabt haben könnte.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Sofern eine Beendigung durch das Verhalten eines Teilnehmers verursacht wurde,
                    kann Lotto BW von dieser Person Ersatz für den durch die Beendigung entstandenen
                    Schaden verlangen.
                </ListItem>
            </List>

            <Headline level="h2">V Sonstiges</Headline>
            <List type="ol" gap="xxxl">
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Diese Teilnahmebedingungen können jederzeit von Lotto BW ohne gesonderte
                    Benachrichtigung geändert werden, sofern dies für Lotto BW notwendig und für den
                    Teilnehmer zumutbar ist.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Ein Gewinn kann im Nachhinein aberkannt werden, wenn sich herausstellt, dass
                    eine Teilnahmeberechtigung nicht bestand oder während des Wettbewerbs entfallen
                    ist. Der Anspruch auf den Gewinn entfällt insbesondere dann, wenn ein Teilnehmer
                    aktiv in den Wettbewerbsablauf eingegriffen oder dies versucht hat, um seine
                    Gewinnchance zu erhöhen.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Der Rechtsweg ist bezüglich der Gewinnermittlung und einer eventuellen
                    Sachmängelhaftung gegenüber Lotto BW ausgeschlossen.
                </ListItem>
            </List>

            <Headline level="h2">VI Datenschutz</Headline>
            <List type="ol" gap="xxxl">
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Lotto BW erhebt und speichert die personenbezogenen Daten der jeweiligen
                    Teilnehmer/in vorrangig zum Zwecke des Wettbewerbs. Die weitere Verwendung Ihrer
                    Daten und Ihre Rechte können Sie unserer{' '}
                    <Link to="https://www.lotto-bw.de/datenschutz">
                        Datenschutzerklärung für Spielteilnehmer und Kunden
                    </Link>{' '}
                    (erhältlich in Ihrer Lotto-Annahmestelle und auf unserer Homepage lotto-bw.de
                    und der Datenschutzerklärung Webanalyse, wenn Sie übers Internet teilnehmen
                    (diese finden Sie auf unserer Homepage{' '}
                    <Link to="https://www.lotto-bw.de/">www.lotto-bw.de</Link>) entnehmen.
                </ListItem>
                <ListItem
                    gap={{
                        small: 'l',
                        medium: 'xl',
                        large: 'xxl',
                    }}
                    type="plain"
                    hasBorder
                >
                    Fragen zum Datenschutz können Sie auch direkt an unseren Datenschutzbeauftragten
                    stellen (<Link type="plain" to="mailto:ZGF0ZW5zY2h1dHooYXQpbG90dG8tYncuZGU=" />).
                    Der Datenschutzbeauftragte ist auch zuständig, wenn Sie meinen, dass wir Ihre
                    Daten missbräuchlich verwendet haben.
                </ListItem>
            </List>

            <Headline level="h2">VII Kontakt</Headline>
            <P>
                Bei Fragen in Bezug auf die Durchführung des Wettbewerbs wenden Sie sich bitte an:{' '}
            </P>

            <P gap="xl">
                <strong>Staatliche Toto-Lotto GmbH Baden-Württemberg</strong>
                <br />
                Unternehmenskommunikation<br />
                Friedemann Häberlen<br />
                Nordbahnhofstraße 201<br />
                70191 Stuttgart<br />
                Tel.: 0711/ 81000-112<br />
                E-Mail:{' '}
                <Link type="plain" to="mailto:ZnJpZWRlbWFubi5oYWViZXJsZW4oYXQpbG90dG8tYncuZGU=" />
            </P>

            <P gap="xxxl">
                <strong>Baden-Württembergische Sportjugend Geschäftsstelle</strong>
                <br />
                Lisa Porada<br />
                Fritz-Walter-Weg 19<br />
                70372 Stuttgart <br />
                Tel.: 0711/28077-863 <br />
                E-Mail: <Link type="plain" to="mailto:bC5wb3JhZGFAbHN2YncuZGU=" />
            </P>
        </Section>
    </BaseLayout>
);

export default TermsPage;
